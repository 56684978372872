import React from 'react'
import './Hero.css'
// topmost element of page, after nav
function Hero() {
  return (
<section class="text-white bgOverlay">
  {/* <img src={require('./Hero.jpeg')} alt="Mambers of e-cell school of computer science (scsit) davv indore" className='bgImage' /> */}
  <picture className='bgImage'>
     <source media='(max-width: 768px)' srcSet={require('./HeroMob.jpeg')} />
     <source media='(min-width: 768px)' srcSet={require('./HeroDesk.jpeg')} />
     <img src={require('./HeroDesk.jpeg')} alt='Mambers of e-cell school of computer science (scsit) davv indore' />
 </picture>
  
  <div class="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
    <div class="mx-auto max-w-3xl text-center">
      <h1
        class="bg-gradient-to-r from-green-300 via-blue-200 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl"
      >
        <span class="font-extrabold  text-blue-500 sm:block">ENTREPRENEURSHIP CELL</span>
        

        <span class="sm:block"> SCSIT DAVV Indore </span>
      </h1>

      <p class="mx-auto mt-10 mb-10 max-w-xl sm:text-xl/relaxed md:text-2xl">
      Fostering Tomorrow's Change-makers: <hr style={{opacity:'0'}} /> 
      <span class="bg-gradient-to-r from-green-300 via-blue-300 to-purple-300 bg-clip-text text-transparent  ">The Cell - Where Entrepreneurship Sparks, Innovation Collaborates, and
Success Unites.</span>
      </p>

      <div class="mt-8 flex flex-wrap justify-center gap-4">
        <a
          class="block w-full rounded border border-blue-400 bg-blue-500 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
          href="/gallery"
        >
          Visit Gallery
        </a>

        <a
          class="block w-full rounded border border-blue-400 px-12 py-3 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
          href={require('./src/E-Cell-Presentation.pdf')}
        >
          Know More
        </a>
      </div>
    </div>
  </div>
</section>

  )
}

export default Hero 
const Team = () => {

    const team = [
        {
            avatar: "SurajKaranSingh.webp",
            name: "Suraj Karan Singh",
            title: "Head",
            linkedin: "https://www.linkedin.com/in/suraj-karan-singh-561a39250",
            Instagram: "https://www.instagram.com/_suraj_30_6ks02",
        },
        {
            avatar: "HarshSoni.webp",
            name: "Harsh Soni",
            title: "Associate Manager",
            linkedin: "https://www.linkedin.com/in/hashprog",
            Instagram: "https://www.instagram.com/hash.prog",
        },
        {
            avatar: "RupalPorwal.webp",
            name: "Rupal Porwal",
            title: "Finance Manager",
            linkedin: "https://www.linkedin.com/in/rupal-porwal-a38828291",
            Instagram: "https://www.instagram.com/rupalporwal4",
        },
        {
            avatar: "NikhilTiwari.webp",
            name: "Nikhil Tiwari",
            title: "Technical Manager",
            linkedin: "https://www.linkedin.com/in/nikhil-tiwari-502177209",
            Instagram: "https://www.instagram.com/au_wild",
        },
        {
            avatar: "SauravThoke.webp",
            name: "Saurav Thoke",
            title: "Technical Manager",
            linkedin: "https://www.linkedin.com/in/saurav-thoke-13268a2b1",
            Instagram: "/soon",
        },
        {
            avatar: "AyushiPawar.webp",
            name: "Ayushi Pawar",
            title: "Management Manager",
            linkedin: "https://www.linkedin.com/in/ayushi-pawar-51210525b",
            Instagram: "https://www.instagram.com/ayushipawar751",
        },
        {
            avatar: "Palash.webp",
            name: "Palash",
            title: "Management Manager",
            linkedin: "https://www.linkedin.com/in/palash-bajpai-a13b61267",
            Instagram: "https://www.instagram.com/palashbajpai_",
        },
        {
            avatar: "KashviJain.webp",
            name: "Kashvi Jain",
            title: "PR Manager",
            linkedin: "https://www.linkedin.com/in/kashvi-jain-aa4b37259",
            Instagram: "https://www.instagram.com/jainkashvi_24",
        },
        {
            avatar: "HarshGupta.webp",
            name: "Harsh Gupta",
            title: "PR Manager",
            linkedin: "https://www.linkedin.com/in/harsh-gupta-365days",
            Instagram: "https://www.instagram.com/harshgupta.sde",
        },
        {
            avatar: "VijayBundela.webp",
            name: "Vijay Bundela",
            title: "Social Media Manager",
            linkedin: "https://www.linkedin.com/in/rvjbundela2024",
            Instagram: "https://www.instagram.com/bundelavj_",
        },
    ];



    return (
        <section className="py-14  dark:bg-gray-900 dark:text-white">
            <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                <div className="max-w-xl">
                    <h3 className="text-gray-800 dark:text-white text-3xl font-semibold sm:text-4xl">
                        Meet our team
                    </h3>
                    <p class="mt-4 text-gray-700 dark:text-gray-300 text-xl">
                        The E-Cell team operates collectively.
                        Together, we contribute to the growth and success of E-Cell, each role playing a distinct part in its functioning.
                    </p>
                    {/* <p className="text-gray-600 mt-3">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy.
                    </p> */}
                </div>
                <div className="mt-12">
                    <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {
                            team.map((item, idx) => (
                                <li key={idx} className="flex gap-4 items-center">
                                    <div className="flex-none w-24 h-24">
                                        <img
                                            src={require(`./src/${item.avatar}`)}
                                            // src={item.avatar}
                                            // src={require('person.png')}
                                            className="object-cover w-full h-full rounded-full"
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <h4 className="text-gray-700 dark:text-gray-200 font-semibold sm:text-lg">{item.name}</h4>
                                        <p className="text-indigo-600 dark:text-sky-500">{item.title}</p>
                                        <div className="mt-3 flex gap-2 text-gray-400">
                                            <a href={item.Instagram}>
                                                {/* replace with insta  */}
                                                <svg className="w-7 h-7 duration-150 hover:text-gray-500" fill="currentColor" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                                                    <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"></stop><stop offset=".328" stop-color="#ff543f"></stop><stop offset=".348" stop-color="#fc5245"></stop><stop offset=".504" stop-color="#e64771"></stop><stop offset=".643" stop-color="#d53e91"></stop><stop offset=".761" stop-color="#cc39a4"></stop><stop offset=".841" stop-color="#c837ab"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"></stop><stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
                                                </svg>
                                                {/* <svg className="w-5 h-5 duration-150 hover:text-gray-500" fill="currentColor" viewBox="0 0 48 48"><g clip-path="url(#clip0_17_80)"><path fill="currentColor" d="M15.1 43.5c18.11 0 28.017-15.006 28.017-28.016 0-.422-.01-.853-.029-1.275A19.998 19.998 0 0048 9.11c-1.795.798-3.7 1.32-5.652 1.546a9.9 9.9 0 004.33-5.445 19.794 19.794 0 01-6.251 2.39 9.86 9.86 0 00-16.788 8.979A27.97 27.97 0 013.346 6.299 9.859 9.859 0 006.393 19.44a9.86 9.86 0 01-4.462-1.228v.122a9.844 9.844 0 007.901 9.656 9.788 9.788 0 01-4.442.169 9.867 9.867 0 009.195 6.843A19.75 19.75 0 010 39.078 27.937 27.937 0 0015.1 43.5z" /></g><defs><clipPath id="clip0_17_80"><path fill="currentColor" d="M0 0h48v48H0z" /></clipPath></defs></svg> */}
                                            </a>
                                            <a href={item.linkedin}>
                                                <svg className="mt-1 w-5 h-5 duration-150 hover:text-sky-500" fill="blue" viewBox="0 0 48 48"><g clip-path="url(#clip0_17_68)"><path fill="currentColor" d="M44.447 0H3.544C1.584 0 0 1.547 0 3.46V44.53C0 46.444 1.584 48 3.544 48h40.903C46.407 48 48 46.444 48 44.54V3.46C48 1.546 46.406 0 44.447 0zM14.24 40.903H7.116V17.991h7.125v22.912zM10.678 14.87a4.127 4.127 0 01-4.134-4.125 4.127 4.127 0 014.134-4.125 4.125 4.125 0 010 8.25zm30.225 26.034h-7.115V29.766c0-2.653-.047-6.075-3.704-6.075-3.703 0-4.265 2.896-4.265 5.887v11.325h-7.107V17.991h6.826v3.13h.093c.947-1.8 3.272-3.702 6.731-3.702 7.21 0 8.541 4.744 8.541 10.912v12.572z" /></g><defs><clipPath id="clip0_17_68"><path fill="currentColor" d="M0 0h48v48H0z" /></clipPath></defs></svg>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Team
import React from 'react'
import Galery from '../components/Galery'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

function Gallery() {
  return (
    <>
    <Nav/>
    <Galery/>
    <Footer/>
    </>
  )
}

export default Gallery
import React from 'react'
// vision page aka cta
function Cta() {
    return (
        <>

            <section id='Vision' class="overflow-hidden bg-gray-50 sm:grid sm:grid-cols-2 dark:bg-gray-900 dark:text-white">
                <div class="p-8 md:p-12 lg:px-16 lg:py-24">
                    <div class="mx-auto max-w-xl  ltr:sm:text-left rtl:sm:text-right">
                        <h2 class="mb-4 text-3xl font-bold text-gray-900 md:text-3xl dark:text-white">
                            Vision
                        </h2>

                        <p class="text-gray-800 text-xl dark:text-gray-300 mt-4 md:mt-4 md:block">
                        Through dynamic activities and workshops, the vision of empowering  the next generation of leaders and change-makers, E-Cell will serve as a catalyst for turning ideas into thriving businesses.
                        </p>
                        <p class="hidden text-gray-800 text-xl dark:text-gray-300 md:mt-6 md:block">
                            We seek to develop a vibrant startup culture, community, and ecosystem on our campus and enable the dreams of young budding entrepreneurs to take their ideas to the next level.
                        </p>

                        <div class="mt-4 md:mt-8">
                            <a
                                href="/team"
                                class="inline-block rounded bg-emerald-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-yellow-400"
                            >
                                Learn More
                            </a>
                        </div>
                    </div>
                </div>

                <img
                    alt="Student"
                    src="https://images.unsplash.com/photo-1464582883107-8adf2dca8a9f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                    class="h-56 w-full object-cover sm:h-full"
                />
            </section>

        </>
    )
}

export default Cta
import React from 'react'
import Nav from '../components/Nav'
import Soon from '../components/Soon'
function About() {
  return (
    <>
    <Nav/>
    <Soon/>
    </>
  )
}

export default About
import React from 'react'
import { useState } from 'react'
import './Nav.css'


function Nav() {
    const [isActive, setIsActive] = useState(false);

    const handleClick = event => {
        // 👇️ toggle isActive state on click
        setIsActive(current => !current);
        const nav = document.getElementById('NavHide');
        const navElement = document.querySelector('#NavHide ul');
        const navGrow = document.getElementById('GrowwOnMob');
        if (isActive) {
            nav.classList.add('hidden');
            navGrow.classList.remove('growwHeight');
            navElement.classList.remove('NavUlFlex');
        }
        else {
            navElement.classList.add('NavUlFlex');
            navGrow.classList.add('growwHeight')
            nav.classList.remove('hidden');
        }
    };


    return (
        <header id='Top' class="bg-white dark:bg-gray-700 dark:text-black z-50">
            <div id='GrowwOnMob' class="mx-auto max-w-screen-xl px-4 md:px-6 lg:px-4">
                <div class="flex h-16 items-center justify-between">
                    <div className='flex flex-row align-center justify-center'>
                        <div class="md:flex md:items-center md:gap-12">
                            <a class="block text-teal-600 dark:text-teal-600" href="/">
                                {/* <span class="sr-only">Home</span> */}

                                {/* <svg class="h-20 mb-4 dark:bg-white" id='Logo' version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 720.000000 745.000000"
                                    preserveAspectRatio="xMidYMid meet">

                                    <g transform="translate(0.000000,745.000000) scale(0.100000,-0.100000)"
                                        fill="#000000" stroke="none">
                                        <path d="M2380 4823 c-109 -22 -154 -33 -184 -49 -16 -8 -37 -14 -46 -14 -9 0
-36 -12 -61 -28 -24 -15 -56 -33 -71 -41 -56 -29 -165 -146 -197 -212 -17 -35
-31 -71 -31 -80 0 -9 -6 -22 -14 -28 -34 -28 -56 -100 -56 -179 0 -149 62
-303 162 -400 60 -58 118 -140 118 -167 0 -8 5 -15 11 -15 7 0 9 -10 5 -24 -4
-18 -1 -28 11 -35 16 -9 16 -12 3 -21 -10 -6 -11 -10 -2 -10 6 0 12 -9 12 -20
0 -11 7 -20 15 -20 18 0 18 -1 6 -32 -9 -25 8 -129 28 -167 7 -12 10 -35 6
-51 -4 -18 0 -40 9 -59 9 -16 16 -45 16 -64 0 -32 31 -87 50 -87 15 0 18 39 3
59 -8 12 -12 25 -9 30 5 8 -1 59 -25 216 -5 33 -11 69 -12 81 -2 11 -7 26 -12
34 -5 8 -9 24 -10 37 0 12 -9 29 -20 37 -11 9 -14 16 -7 16 13 0 18 30 4 30
-4 0 -17 21 -29 48 -22 46 -52 86 -111 147 -42 44 -72 101 -79 152 -3 24 -11
50 -17 56 -34 35 -83 250 -64 279 4 7 8 41 8 76 0 34 4 62 10 62 6 0 10 8 10
19 0 10 7 24 15 31 8 7 15 18 15 24 0 13 54 66 67 66 22 0 91 46 105 70 25 45
223 150 282 150 11 0 34 7 50 15 15 8 51 15 80 16 66 0 139 28 126 49 -11 18
-85 20 -170 3z"/>
                                        <path d="M2630 4833 c-49 -8 -55 -13 -34 -27 27 -18 131 -49 142 -42 5 3 27
-1 48 -8 22 -8 59 -20 84 -27 60 -17 174 -76 231 -119 93 -70 197 -166 214
-197 19 -34 35 -43 35 -19 0 18 -19 53 -52 97 -16 20 -28 43 -28 51 0 9 -7 18
-15 22 -8 3 -14 9 -13 13 5 19 -24 51 -64 68 -24 11 -56 31 -72 46 -15 15 -40
32 -55 37 -182 66 -233 82 -263 82 -14 0 -42 7 -61 15 -36 15 -49 16 -97 8z"/>
                                        <path d="M4096 4755 c-11 -8 -30 -15 -43 -15 -21 0 -23 -4 -23 -52 0 -43 5
-57 27 -80 23 -24 35 -28 79 -28 69 0 81 6 104 50 17 33 17 41 6 75 -8 21 -22
44 -32 52 -24 17 -94 16 -118 -2z"/>
                                        <path d="M3357 4363 c-11 -10 -8 -56 4 -92 17 -47 -3 -141 -64 -306 -48 -133
-60 -159 -84 -192 -13 -17 -23 -36 -23 -42 0 -6 -4 -11 -10 -11 -5 0 -10 -6
-10 -14 0 -7 -7 -19 -15 -26 -8 -7 -15 -19 -15 -26 0 -8 7 -14 15 -14 8 0 15
6 15 14 0 8 7 17 16 20 9 3 14 12 11 19 -2 6 11 33 29 60 19 26 34 52 34 57 0
6 3 10 8 10 4 0 17 15 30 33 12 19 33 40 46 47 20 10 23 18 19 51 -3 23 -1 39
4 39 34 0 75 254 49 298 -33 55 -53 81 -59 75z"/>
                                        <path d="M2818 3980 c-14 -4 -45 -18 -68 -31 -34 -19 -44 -21 -58 -11 -61 45
-153 53 -188 17 -9 -8 -20 -15 -25 -15 -5 0 -9 -6 -9 -14 0 -24 -23 -36 -44
-21 -10 8 -15 18 -12 24 3 5 -14 19 -38 32 -33 16 -55 20 -86 16 -41 -5 -84
-32 -75 -47 2 -4 -2 -16 -10 -26 -8 -10 -15 -28 -15 -40 0 -28 53 -135 97
-194 34 -46 119 -117 128 -108 3 2 -7 21 -22 42 -14 21 -25 50 -25 65 0 15
-12 38 -30 56 -76 76 -115 166 -80 184 24 12 82 2 115 -21 15 -11 27 -17 27
-13 0 3 12 -2 26 -11 25 -17 26 -17 47 9 18 20 34 27 83 32 53 6 68 3 114 -19
48 -23 55 -24 70 -11 9 8 29 15 43 15 15 0 27 4 27 8 0 5 11 14 25 20 20 9 33
9 60 -3 19 -7 35 -16 35 -19 0 -14 -53 -99 -99 -160 -28 -37 -55 -81 -58 -97
-3 -16 -10 -32 -14 -35 -5 -3 -9 -15 -9 -27 0 -19 5 -16 36 21 20 23 45 42 55
42 10 0 21 10 24 23 4 12 15 31 25 42 9 11 34 49 54 84 32 55 36 68 27 88 -6
13 -11 32 -11 42 0 43 -81 78 -142 61z"/>
                                        <path d="M3123 3617 c-4 -8 -16 -16 -25 -20 -11 -4 -15 -12 -12 -22 4 -9 0
-15 -10 -15 -28 0 -39 -36 -21 -70 14 -27 14 -32 1 -46 -18 -18 -27 -53 -50
-194 -9 -57 -22 -110 -27 -116 -14 -17 -7 -106 8 -111 15 -5 41 44 53 100 5
23 16 69 25 102 9 33 23 94 31 135 7 41 18 85 24 97 16 37 41 154 34 164 -8
13 -22 11 -31 -4z"/>
                                        <path d="M2518 3603 c-16 -8 -33 -23 -38 -34 -6 -10 -19 -19 -29 -19 -24 0
-31 -19 -31 -84 0 -29 -5 -57 -10 -62 -7 -7 -7 -32 0 -80 6 -38 12 -103 13
-144 2 -68 4 -75 22 -75 15 0 20 7 20 27 0 14 3 30 7 34 4 4 3 13 -3 21 -9 9
-8 13 0 13 15 0 7 238 -9 254 -5 5 -10 22 -10 38 0 45 39 58 158 54 55 -2 103
-6 109 -9 12 -8 7 -103 -7 -138 -9 -21 -9 -37 -1 -60 13 -38 15 -101 3 -114
-14 -16 -2 -116 14 -122 32 -12 46 18 42 94 -2 43 1 74 7 76 6 3 6 15 -2 35
-6 19 -7 34 -2 38 13 8 11 52 -5 87 -8 18 -17 49 -21 70 -3 20 -10 37 -15 37
-6 0 -10 8 -10 18 0 49 -138 79 -202 45z m-51 -349 c-9 -9 -28 6 -21 18 4 6
10 6 17 -1 6 -6 8 -13 4 -17z m273 -84 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
                                        <path d="M2557 3323 c-4 -3 -7 -11 -7 -17 0 -7 3 -7 8 1 4 6 25 13 47 16 l40
4 -41 1 c-22 1 -44 -1 -47 -5z"/>
                                        <path d="M2640 3216 c0 -10 -13 -16 -42 -18 -23 -2 -43 -8 -44 -16 -1 -7 0 -8
3 -2 2 5 25 10 49 10 37 0 44 3 44 20 0 11 -2 20 -5 20 -3 0 -5 -6 -5 -14z"/>
                                        <path d="M2595 3160 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
                                        <path d="M2800 3150 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
                                        <path d="M3270 3100 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
                                        <path d="M2545 2989 c-181 -7 -265 -17 -265 -30 0 -4 29 -11 65 -14 36 -4 65
-11 65 -15 0 -9 56 -31 63 -24 9 9 86 16 261 22 153 6 180 9 190 24 5 9 7 21
2 25 -12 13 -198 19 -381 12z"/>
                                        <path d="M2465 2862 c-6 -7 -158 -32 -194 -32 -67 0 -19 -14 82 -23 115 -11
162 -11 401 -4 137 4 172 8 185 22 13 13 14 17 2 24 -20 13 -464 25 -476 13z"/>
                                        <path d="M2440 2730 c-47 -4 -103 -11 -125 -15 -32 -7 -36 -10 -20 -16 11 -4
41 -8 67 -8 37 -1 50 -5 58 -22 11 -19 17 -20 83 -14 40 3 136 9 214 13 136 7
174 16 159 40 -5 8 -118 18 -291 26 -33 1 -98 -1 -145 -4z"/>
                                        <path d="M2411 2613 c-20 -7 -46 -13 -58 -13 -13 0 -23 -4 -23 -9 0 -10 49
-21 93 -21 16 0 36 -8 44 -17 8 -10 16 -13 19 -8 2 6 51 9 127 7 106 -3 125
-2 137 13 12 15 12 19 -3 30 -34 25 -279 38 -336 18z"/>
                                        <path d="M2472 2470 c-16 -26 -15 -30 7 -30 11 0 23 -5 26 -11 9 -14 105 -2
124 16 7 7 11 21 8 29 -5 13 -22 16 -79 16 -62 0 -75 -3 -86 -20z"/>
                                        <path d="M2575 2321 c-27 -15 -122 -70 -210 -121 -185 -107 -202 -117 -298
-171 -40 -22 -79 -47 -87 -55 -23 -22 -28 -748 -6 -773 20 -22 143 -87 150
-80 3 3 7 171 8 372 3 416 -5 379 93 432 28 15 128 72 223 128 153 88 177 99
200 91 56 -20 34 -42 -136 -139 -89 -52 -177 -102 -195 -112 -17 -10 -49 -31
-70 -46 l-37 -29 2 -381 3 -380 40 -24 c101 -59 113 -64 119 -47 3 9 6 177 6
374 l0 358 30 26 c17 14 68 46 113 71 45 25 96 54 112 64 190 113 242 138 262
125 20 -13 25 -25 15 -40 -4 -5 -99 -63 -212 -128 -113 -65 -215 -127 -227
-139 l-23 -20 0 -371 c0 -204 4 -376 8 -382 10 -15 125 -84 140 -84 14 0 13
-20 18 405 l3 330 34 30 c19 17 61 43 93 60 33 16 66 34 74 41 14 12 49 32
135 79 55 30 106 60 152 87 21 13 54 32 75 43 58 30 82 51 75 67 -3 8 -90 63
-194 123 -103 59 -204 117 -223 128 -92 54 -206 117 -210 117 -3 0 -27 -13
-55 -29z"/>
                                        <path d="M3249 1922 c-13 -10 -37 -24 -54 -32 -16 -8 -52 -29 -80 -46 -27 -17
-86 -51 -130 -74 -44 -24 -92 -51 -107 -62 -14 -10 -31 -18 -37 -18 -6 0 -11
-4 -11 -9 0 -5 -12 -14 -27 -20 -46 -17 -117 -61 -135 -83 -16 -18 -18 -51
-18 -369 0 -212 4 -350 10 -354 5 -3 27 6 49 20 23 14 43 25 47 25 3 0 18 8
32 19 15 10 63 39 107 63 214 119 352 202 373 224 19 21 22 36 22 100 0 80 -5
85 -48 55 -65 -46 -396 -231 -413 -231 -13 0 -19 7 -19 23 1 60 18 76 185 169
44 25 96 54 115 65 19 11 61 35 93 53 89 50 87 44 87 282 0 114 -4 209 -9 212
-5 3 -19 -2 -32 -12z m-118 -278 c22 -27 -16 -81 -81 -116 -30 -16 -73 -40
-95 -53 -93 -53 -116 -65 -130 -65 -21 0 -19 53 3 78 16 19 268 172 283 172 4
0 13 -7 20 -16z"/>
                                        <path d="M4237 1865 c-4 -8 -14 -15 -23 -15 -22 0 -133 -108 -140 -137 -4 -13
-10 -23 -15 -23 -5 0 -9 -13 -9 -29 0 -17 -4 -33 -10 -36 -6 -4 -10 -63 -10
-145 0 -82 4 -141 10 -145 6 -3 10 -15 10 -26 0 -10 6 -29 12 -42 21 -37 121
-137 138 -137 9 0 21 -5 27 -11 5 -5 37 -9 69 -7 55 3 59 5 62 28 2 17 -6 34
-23 50 l-25 23 0 278 0 277 26 16 c21 14 25 24 22 54 l-3 37 -56 3 c-44 2 -58
-1 -62 -13z"/>
                                        <path d="M4377 1873 c-23 -22 17 -93 52 -93 31 0 111 -119 111 -165 0 -13 4
-26 9 -30 15 -9 71 -13 76 -5 2 3 5 71 6 151 l1 144 -30 3 c-23 2 -37 -3 -53
-22 -18 -21 -24 -23 -35 -11 -8 8 -23 12 -33 10 -11 -2 -25 3 -32 11 -12 14
-60 19 -72 7z"/>
                                        <path d="M4836 1850 c-85 -3 -131 -9 -133 -17 -2 -6 12 -24 31 -40 l36 -28 0
-275 c-1 -285 -4 -310 -43 -310 -22 0 -28 -25 -11 -49 14 -20 24 -21 165 -21
l149 0 0 369 c0 395 1 385 -48 379 -9 -1 -75 -5 -146 -8z"/>
                                        <path d="M5063 1853 c-7 -3 -13 -17 -13 -33 0 -22 5 -29 28 -33 40 -8 117 -80
136 -127 14 -36 20 -41 44 -38 l27 3 3 113 3 112 -88 1 c-48 0 -97 2 -108 4
-11 1 -26 1 -32 -2z"/>
                                        <path d="M5390 1852 c-20 -7 -11 -57 13 -67 22 -10 22 -10 25 -293 l2 -283
-26 -24 c-16 -15 -24 -32 -22 -47 3 -23 5 -23 147 -26 109 -2 146 1 152 10 5
7 8 155 8 328 0 288 1 316 17 327 10 7 24 13 32 13 9 0 12 10 10 32 l-3 31
-172 1 c-95 1 -177 0 -183 -2z"/>
                                        <path d="M6165 1852 c-108 -2 -172 -6 -176 -13 -11 -18 1 -47 22 -52 18 -5 19
-18 19 -294 l0 -289 -24 -21 c-17 -15 -22 -28 -19 -47 l5 -26 149 0 149 0 0
324 c0 282 2 327 16 340 8 9 24 16 35 16 14 0 19 7 19 29 0 16 -6 30 -12 32
-7 2 -89 3 -183 1z"/>
                                        <path d="M5133 1644 c-7 -3 -13 -11 -13 -18 0 -26 -31 -74 -50 -79 -33 -9 -26
-62 14 -109 19 -22 36 -49 38 -61 4 -25 30 -42 52 -34 15 6 16 24 14 154 -3
140 -4 148 -23 150 -11 1 -26 -1 -32 -3z"/>
                                        <path d="M3572 1501 c-8 -5 -12 -23 -10 -47 l3 -39 185 0 185 0 3 39 c2 24 -2
42 -10 47 -7 5 -87 9 -178 9 -91 0 -171 -4 -178 -9z"/>
                                        <path d="M5861 1344 c-12 -25 -21 -50 -21 -53 0 -15 -72 -91 -86 -91 -26 0
-55 -35 -51 -61 4 -24 5 -24 118 -24 l114 0 0 135 0 135 -27 3 c-23 3 -29 -3
-47 -44z"/>
                                        <path d="M6468 1347 c-10 -23 -18 -47 -18 -53 0 -15 -81 -94 -96 -94 -20 0
-46 -38 -42 -62 3 -22 6 -23 112 -26 81 -2 112 1 118 10 10 17 9 215 -3 245
-15 40 -51 30 -71 -20z"/>
                                        <path d="M5239 1353 c-8 -10 -22 -34 -32 -53 -10 -19 -23 -42 -30 -50 -16 -21
-63 -50 -80 -50 -22 0 -49 -36 -45 -62 3 -22 6 -23 125 -26 l122 -3 3 103 c4
134 -22 191 -63 141z"/>
                                        <path d="M4541 1290 c-23 -52 -81 -100 -135 -114 -22 -5 -27 -11 -24 -34 3
-24 8 -27 47 -30 54 -4 97 16 141 65 46 52 60 77 60 111 0 33 -16 52 -45 52
-16 0 -27 -13 -44 -50z"/>
                                    </g>
                                </svg> */}
                                <img src={require('../e-cell.png')} alt='logo of e-cell davv' className='h-20 w-20 object-cover' />
                            </a>
                        </div>
                        <a class="block text-teal-600 dark:text-teal-600" href="https://www.dauniv.ac.in/new/scs/" rel="noreferrer" target='_blank'>
                            <img className="h-12 mt-5 ml-3  dark:bg-white" src={require('./scs-logo.png')} alt="scsit logo | school of computer science logo" />
                        </a>

                    </div>

                    <div id='NavHide' class="hidden md:block">
                        <nav aria-label="Global">
                            <ul class="flex items-center  gap-6 text-md">

                                <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/#about"
                                    >
                                        About
                                    </a>
                                </li>

                                <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/Team"
                                    >
                                        Team
                                    </a>
                                </li>

                                <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/gallery"
                                    >
                                        Gallery
                                    </a>
                                </li>


                                <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/#Vision"
                                    >
                                        Vision
                                    </a>
                                </li>

                                <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/#Activities"
                                    >
                                        Activities
                                    </a>
                                </li>

                                <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/soon"
                                    >
                                        Projects
                                    </a>
                                </li>

                                {/* <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/"
                                    >
                                        Blog
                                    </a>
                                </li> */}
                            </ul>
                        </nav>
                    </div>


                    <div class="flex items-center gap-4">

                        {/* join btn without js */}
                        <ul class="space-y-1">
                            <details class="group [&_summary::-webkit-details-marker]:hidden">
                                <summary
                                    class=" relative rounded-md bg-teal-600 px-5 py-2.5 w-20 text-md font-medium text-white shadow dark:hover:bg-teal-600  hover:bg-teal-500 hover:text-white flex cursor-pointer items-center justify-between "
                                >
                                    <span class="text-sm font-medium w-20"> Join </span>

                                    <span class="shrink-0 transition duration-300 group-open:-rotate-180">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </span>
                                </summary>

                                <ul className='absolute z-10'>
                                    <li className='my-6'>
                                        <a
                                            target='_blank' rel='noreferrer'
                                            href="https://docs.google.com/forms/d/e/1FAIpQLScRIzEVPTMl40pKxEWl1GTm8EP00BhNVGvl69RMeCbJJk_7qw/viewform?usp=sf_link"
                                            class=" rounded-lg  px-5 py-3 text-sm font-medium text-gray-500 hover:bg-teal-500 bg-gray-300 hover:text-white"
                                        >
                                            As Member
                                        </a>
                                    </li>

                                    <li className='my-5'>
                                        <a
                                            target='_blank' rel='noreferrer'
                                            href="https://docs.google.com/forms/d/e/1FAIpQLSd1qudhaQX28Vpo5_Pg30BSL5RxQsdjmrNesPLeL3Yb3JGGDg/viewform?usp=sf_link"
                                            class=" rounded-lg px-5 py-3 text-sm font-medium text-gray-500 hover:bg-teal-500 bg-gray-300 hover:text-white"
                                        >
                                            As A Learner
                                        </a>
                                    </li>
                                </ul>
                            </details>
                        </ul>
                        {/* end of Join btn  */}
                        <div class="block md:hidden">
                            <button
                                onClick={handleClick}
                                class="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75 dark:bg-gray-800 dark:text-white dark:hover:text-white/75"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}

export default Nav
import React from 'react'

function Outline() {
    return (
        <>

            <section id='Activities' className='dark:bg-gray-900 dark:text-white'>
                <div class="max-w-screen-xxl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
                        <div class="mx-auto text-center lg:mx-0 ltr:lg:text-left rtl:lg:text-right max-w-2xl">
                            <h2 class="text-3xl font-bold sm:text-4xl">Activities</h2>

                            <p class="mt-8 md:ml-14 text-gray-900 dark:text-gray-200 text-justify  sm:text-xl ">
                                Our mission is to empower future-preneurs to conceive and develop novel business ideas while benefiting from the support
                                and safety nets provided by existing organizations, thereby fostering a community that thrives on innovation, resilience, and
                                an unwavering pursuit of excellence
                            </p>

                            {/* <a
                                href="#"
                                class="mt-8 inline-block rounded bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                            >
                                Get Started Today
                            </a> */}
                        </div>

                        <div class="grid grid-cols-1 gap-4 sm:grid-cols-3">
                            
                            <a
                                class="block  dark:bg-zinc-900 rounded-xl border border-blue-200 p-4 shadow-sm hover:border-blue-500 hover:bg-slate-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                                href="/#Activities"
                            >
                                <span class="inline-block rounded-lg bg-gray-50 p-3">
                                    <svg
                                        class="h-6 w-6 dark:bg-gray-900 dark:text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                                        <path
                                            d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                                        ></path>
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                                        ></path>
                                    </svg>
                                </span>

                                <h2 class="mt-2 font-bold  text-black dark:text-blue-600">Bootcamps</h2>

                                <p class=" sm:mt-1 sm:block sm:text-sm dark:hover:text-black dark:text-gray-200 text-black">
                                    Organizing Bootcamps in the college premises like Bootcamp on Blockchain, Coding, AI, Personality
                                    Development, Web Development etc.
                                </p>
                            </a>

                            <a
                                class="block  dark:bg-zinc-900 rounded-xl border border-blue-200 p-4 shadow-sm hover:border-blue-500 hover:bg-slate-200  hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                                href="/#Activities"
                            >
                                <span class="inline-block rounded-lg bg-gray-50 p-3">
                                    <svg
                                        class="h-6 w-6 dark:bg-gray-900 dark:text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                                        <path
                                            d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                                        ></path>
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                                        ></path>
                                    </svg>
                                </span>

                                <h2 class="mt-2 font-bold  text-black dark:text-blue-600 ">Workshops</h2>
{/* place hidden class to hide the para on mobile screen */}
                                <p class=" sm:mt-1 sm:block sm:text-sm dark:hover:text-black dark:text-gray-200 text-black">
                                    Interactive workshops on IOT, Soft-skills, Data science, New AI tools, Product Development, Entrepreneurship
                                    etc.
                                </p>
                            </a>
                            <a
                                class="block  dark:bg-zinc-900 rounded-xl border border-blue-200 p-4 shadow-sm hover:border-blue-500 hover:bg-slate-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                                href="/#Activities"
                            >
                                <span class="inline-block rounded-lg bg-gray-50 p-3">
                                    <svg
                                        class="h-6 w-6 dark:bg-gray-900 dark:text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                                        <path
                                            d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                                        ></path>
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                                        ></path>
                                    </svg>
                                </span>

                                <h2 class="mt-2 font-bold  text-black dark:text-blue-600 ">Sessions</h2>

                                <p class=" sm:mt-1 sm:block sm:text-sm dark:hover:text-black dark:text-gray-200 text-black">
                                    Engaging and interactive sessions by industry experts, visiting faculties. It will include sessions like Networking
                                    on social media, content creation, resume building etc.

                                </p>
                            </a>

                            <a
                                class="block  dark:bg-zinc-900 rounded-xl border border-blue-200 p-4 shadow-sm hover:border-blue-500 hover:bg-slate-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                                href="/#Activities"
                            >
                                <span class="inline-block rounded-lg bg-gray-50 p-3">
                                    <svg
                                        class="h-6 w-6 dark:bg-gray-900 dark:text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                                        <path
                                            d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                                        ></path>
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                                        ></path>
                                    </svg>
                                </span>

                                <h2 class="mt-2 font-bold  text-black dark:text-blue-600 ">Startup Ideation Challenge</h2>

                                <p class=" sm:mt-1 sm:block sm:text-sm dark:hover:text-black dark:text-gray-200 text-black">
                                    Teams brainstorm and pitch innovative startup ideas. This event encourages creativity, teamwork, and the
                                    ability to articulate and sell a business concept.
                                </p>
                            </a>

                            <a
                                class="block  dark:bg-zinc-900 rounded-xl border border-blue-200 p-4 shadow-sm hover:border-blue-500 hover:bg-slate-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                                href="/#Activities"
                            >
                                <span class="inline-block rounded-lg bg-gray-50 p-3">
                                    <svg
                                        class="h-6 w-6 dark:bg-gray-900 dark:text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                                        <path
                                            d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                                        ></path>
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                                        ></path>
                                    </svg>
                                </span>

                                <h2 class="mt-2 font-bold  text-black dark:text-blue-600 ">Networking Treasure Hunt</h2>

                                <p class=" sm:mt-1 sm:block sm:text-sm dark:hover:text-black dark:text-gray-200 text-black">
                                    Teams navigate a networking treasure hunt, where they must connect with various industry professionals,
                                    mentors, or alumni. This event promotes networking skills and relationship building.
                                </p>
                            </a>

                            <a
                                class="block  dark:bg-zinc-900 rounded-xl border border-blue-200 p-4 shadow-sm hover:border-blue-500 hover:bg-slate-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                                href="/#Activities"
                            >
                                <span class="inline-block rounded-lg bg-gray-50 p-3">
                                    <svg
                                        class="h-6 w-6 dark:bg-gray-900 dark:text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                                        <path
                                            d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                                        ></path>
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                                        ></path>
                                    </svg>
                                </span>

                                <h2 class="mt-2 font-bold  text-black dark:text-blue-600 ">Leadership and Team Dynamics Workshop</h2>

                                <p class=" sm:mt-1 sm:block sm:text-sm dark:hover:text-black dark:text-gray-200 text-black">
                                    A workshop-style event where teams participate in activities and discussions focused on leadership, effective
                                    communication, and team dynamics.
                                </p>
                            </a>

                           
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Outline
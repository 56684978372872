import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import About from '../components/About'
import People from '../components/Team'
function Team() {
  return (
    <>
      <Nav />
      <About />
      <People />
      <section class="bg-gray-900 text-white ">
        <div class="max-w-screen-xl m-auto  px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
          <div class="max-w-xl">
            <h2 class="text-3xl font-bold sm:text-4xl">Our Responsiblities</h2>

            {/* <p class="mt-4 text-gray-300">
              The E-Cell team operates collectively.
               Together, we contribute to the growth and success of E-Cell, each role playing a distinct part in its functioning.
            </p> */}
          </div>

          <div class="mt-8 grid grid-cols-1 gap-8 justify-items-center md:mt-16 md:grid-cols-2 md:gap-12 lg:grid-cols-3 ">
            <div class="flex items-start gap-4">
              <span class="shrink-0 rounded-lg bg-gray-800 p-4">
                <svg
                  class="h-5 w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                  <path
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  ></path>
                </svg>
              </span>

              <div>
                <h2 class="text-lg font-bold">Head of e-cell</h2>

                <p class="mt-1 text-sm text-gray-300">
                  Responsible for overseeing and
                  leading the Entrepreneurship Cell
                  (E-Cell). Develops the overall
                  strategy and vision for the E-Cell
                </p>
              </div>
            </div>

            <div class="flex items-start gap-4">
              <span class="shrink-0 rounded-lg bg-gray-800 p-4">
                <svg
                  class="h-5 w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                  <path
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  ></path>
                </svg>
              </span>

              <div>
                <h2 class="text-lg font-bold">Associate manager</h2>

                <p class="mt-1 text-sm text-gray-300">
                  Assists the Head of E-Cell in
                  executing the cell's strategy. Acts
                  as a bridge between different
                  functional areas within the E-Cell.
                </p>
              </div>
            </div>
            <div class="flex items-start gap-4">
              <span class="shrink-0 rounded-lg bg-gray-800 p-4">
                <svg
                  class="h-5 w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                  <path
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  ></path>
                </svg>
              </span>

              <div>
                <h2 class="text-lg font-bold">Technical manager</h2>

                <p class="mt-1 text-sm text-gray-300">
                  Manages E-Cell's technology
                  infrastructure, website, databases,
                  and integration, ensuring seamless
                  technical operations for events and
                  activities.
                </p>
              </div>
            </div>
            {/* pr manager */}
            <div class="flex items-start gap-4">
              <span class="shrink-0 rounded-lg bg-gray-800 p-4">
                <svg
                  class="h-5 w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                  <path
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  ></path>
                </svg>
              </span>

              <div>
                <h2 class="text-lg font-bold">PR manager</h2>

                <p class="mt-1 text-sm text-gray-300">
                  PR Managers shape a positive
                  image through media, crisis
                  handling, strategic planning, events,
                  and stakeholder communication.
                </p>
              </div>
            </div>



            <div class="flex items-start gap-4">
              <span class="shrink-0 rounded-lg bg-gray-800 p-4">
                <svg
                  class="h-5 w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                  <path
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  ></path>
                </svg>
              </span>

              <div>
                <h2 class="text-lg font-bold">Management Team</h2>

                <p class="mt-1 text-sm text-gray-300">
                  Collaborates for E-Cell's success,
                  ensuring regular communication,
                  updates, and addressing
                  challenges, with each member
                  contributing expertise for holistic
                  growth.
                </p>
              </div>
            </div>

            <div class="flex items-start gap-4">
              <span class="shrink-0 rounded-lg bg-gray-800 p-4">
                <svg
                  class="h-5 w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                  <path
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  ></path>
                </svg>
              </span>

              <div>
                <h2 class="text-lg font-bold">Event manager</h2>

                <p class="mt-1 text-sm text-gray-300">
                  Plans and executes E-Cell events,
                  coordinating logistics, schedules,
                  and communication with
                  participants, speakers, and
                  sponsors, in alignment with overall
                  objectives.
                </p>
              </div>
            </div>


            <div class="flex items-start gap-4">
              <span class="shrink-0 rounded-lg bg-gray-800 p-4">
                <svg
                  class="h-5 w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                  <path
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  ></path>
                </svg>
              </span>

              <div>
                <h2 class="text-lg font-bold">Finance manager</h2>

                <p class="mt-1 text-sm text-gray-300">
                  Manages the budget and finances
                  of the E-Cell. Develops financial
                  plans and ensures responsible
                  spending Provides financial reports
                  and updates to the management
                  team.
                </p>
              </div>
            </div>
            <div class="flex items-start gap-4">
              <span class="shrink-0 rounded-lg bg-gray-800 p-4">
                <svg
                  class="h-5 w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                  <path
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  ></path>
                </svg>
              </span>

              <div>
                <h2 class="text-lg font-bold">Social media
                  manager</h2>

                <p class="mt-1 text-sm text-gray-300">
                  Manages the E-Cell's presence on
                  social media platforms. Creates
                  engaging content to promote
                  events, initiatives, and activities.
                  Builds and maintains relationships
                  with the online community.
                </p>
              </div>
            </div>
            {/* <div class="flex items-start gap-4">
              <span class="shrink-0 rounded-lg bg-gray-800 p-4">
                <svg
                  class="h-5 w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                  <path
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  ></path>
                </svg>
              </span>

              <div>
                <h2 class="text-lg font-bold">role +1 nas role+2(to be asked and updated)</h2>

                <p class="mt-1 text-sm text-gray-300">
                  role+1+2 rescription
                </p>
              </div>
            </div> */}

          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Team
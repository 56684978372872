import React from 'react'

function About() {
  return (
    <>

      <section className="py-14 bg-white dark:bg-gray-900 dark:text-white" id='about'>
        <div className="overflow-hidden px-4 bg-gray-100 sm:grid sm:grid-cols-2 dark:bg-gray-900 dark:text-white gap-4">
            <img src={require('./src/e-cell-induction.webp')} className="h-56 w-full object-cover sm:h-full" alt="e-cell inaugration, wmpowering studnets" />
          {/* <div className="sm:hidden mt-14 lg:block lg:max-w-xl">
          </div> */}
          <div className="mt-6 lg:ml-8 sm:ml-0 gap-12 sm:mt-0 md:flex lg:items-center">
            <div className="max-w-2xl">
              <h3 className="text-gray-800 dark:text-white text-3xl font-semibold sm:text-4xl">
                About E-Cell SCSIT
              </h3>
              {/* <p className="mt-3 max-w-xl">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis sollicitudin quam ut tincidunt.
              </p> */}
              <p class="mt-8 text-gray-700 dark:text-gray-300 sm:text-xl">
                Entrepreneurship Cell is a student body based in SCSIT, DAVV Indore which aims at fostering entrepreneurial spirit amongst young aspirants by providing them with a platform and required resources for actuating their ideas into successful business ventures.
              </p>
              <p class="mt-4 text-gray-700 dark:text-gray-300 sm:text-xl">E-Cell strives at attaining an ideal entrepreneurial environment in the campus and we believe in taking strides towards establishing an ever-growing, an ever-improving Start-Up environment.
              </p>
              <p class="mt-4 text-gray-700 dark:text-gray-300 sm:text-xl">Our sole approach is to magnify the reach and to set up a diverse pool of investors, evaluators and mentors.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section id='about' class="bg-white dark:bg-gray-900 dark:text-white">
        <div class="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 md:py-16 lg:px-8">
          <div class="mx-auto max-w-3xl text-center">
            <h2 class="text-3xl font-bold text-gray-900 sm:text-4xl dark:text-white">About Us</h2>

            <p class="mt-8 text-gray-700 dark:text-gray-300 sm:text-xl">
              Entrepreneurship Cell is a student body based in SCSIT, DAVV Indore which aims at fostering entrepreneurial spirit amongst young aspirants by providing them with a platform and required resources for actuating their ideas into successful business ventures.
            </p>
            <p class="mt-4 text-gray-700 dark:text-gray-300 sm:text-xl">E-Cell strives at attaining an ideal entrepreneurial environment in the campus and we believe in taking strides towards establishing an ever-growing, an ever-improving Start-Up environment.
            </p>
            <p class="mt-4 text-gray-700 dark:text-gray-300 sm:text-xl">Our sole approach is to magnify the reach and to set up a diverse pool of investors, evaluators and mentors.
            </p>
          </div>

          <div class="mt-8 sm:mt-12">
      <dl class="grid grid-cols-1 gap-4 sm:grid-cols-3">
        <div class="flex flex-col rounded-lg bg-blue-100 px-4 py-8 text-center">
          <dt class="order-last text-lg font-medium text-gray-700 dark:text-gray-300">Total Sales</dt>

          <dd class="text-4xl font-extrabold text-blue-600 md:text-5xl">$4.8m</dd>
        </div>

        <div class="flex flex-col rounded-lg bg-blue-100 px-4 py-8 text-center">
          <dt class="order-last text-lg font-medium text-gray-700 dark:text-gray-300">Official Addons</dt>

          <dd class="text-4xl font-extrabold text-blue-600 md:text-5xl">24</dd>
        </div>

        <div class="flex flex-col rounded-lg bg-blue-100 px-4 py-8 text-center">
          <dt class="order-last text-lg font-medium text-gray-700 dark:text-gray-300">Total Addons</dt>

          <dd class="text-4xl font-extrabold text-blue-600 md:text-5xl">86</dd>
        </div>
      </dl>
    </div> 
        </div>
      </section> */}

    </>
  )
}

export default About
import React from 'react'

function Gallery() {
  return (
    <>
      {/* joining */}
      <section className='bg-white dark:bg-gray-900 dark:text-white'>
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <header className="text-center">
            <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">Team Joining Process</h2>

            {/* <p className="mx-auto mt-4 max-w-md text-gray-500">
             Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque praesentium cumque iure
             dicta incidunt est ipsam, officia dolor fugit natus?
            </p> */}
          </header>

          <ul className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-3">
            <li>
              <a href="/" className="group relative block">
                <img
                  src={require('./src/e-cell-selection.webp')}
                  alt=""
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className=" hidden text-xl font-medium text-white">Casual Trainers</h3>


                </div>
              </a>
            </li>

            <li>
              <a href="/" className="group relative block">
                <img
                  src={require('./src/e-cell-selection-2.webp')}
                  alt=""
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className=" hidden text-xl font-medium text-white">Winter Jumpers</h3>


                </div>
              </a>
            </li>

            <li className="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
              <a href="/" className="group relative block">
                <img
                  src={require('./src/e-cell-collage.webp')}
                  alt=""
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className=" hidden text-xl font-medium text-white">Skinny Jeans Blue</h3>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>
      {/* Induction */}
      <section className='bg-white dark:bg-gray-900 dark:text-white'>
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <header className="text-center">
            <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">Induction</h2>

            {/* <p className="mx-auto mt-4 max-w-md text-gray-500">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque praesentium cumque iure
        dicta incidunt est ipsam, officia dolor fugit natus?
      </p> */}
          </header>

          <ul className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-3">
            <li>
              <a href="/" className="group relative block">
                <img
                  src={require('./src/e-cell-induction.webp')}
                  alt=""
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className=" hidden text-xl font-medium text-white">Casual Trainers</h3>


                </div>
              </a>
            </li>

            <li>
              <a href="/" className="group relative block">
                <img
                  src={require('./src/e-cell-selection-2.webp')}
                  alt=""
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className=" hidden text-xl font-medium text-white">Winter Jumpers</h3>


                </div>
              </a>
            </li>

            <li className="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
              <a href="/" className="group relative block">
                <img
                  src={require('./src/e-cell-induction-2.webp')}
                  alt=""
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className=" hidden text-xl font-medium text-white">Skinny Jeans Blue</h3>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>
      {/* workshop */}
      <section className='bg-white dark:bg-gray-900 dark:text-white'>
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <header className="text-center">
            <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">Workshop</h2>

            {/* <p className="mx-auto mt-4 max-w-md text-gray-500">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque praesentium cumque iure
        dicta incidunt est ipsam, officia dolor fugit natus?
      </p> */}
          </header>

          <ul className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-3">
            <li>
              <a href="/" className="group relative block">
                <img
                  src={require('./src/e-cell-workshop.webp')}
                  alt=""
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className=" hidden text-xl font-medium text-white">Casual Trainers</h3>


                </div>
              </a>
            </li>

            <li>
              <a href="/" className="group relative block">
                <img
                  src={require('./src/e-cell-workshop-2.webp')}
                  alt=""
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className=" hidden text-xl font-medium text-white">Winter Jumpers</h3>


                </div>
              </a>
            </li>

            <li className="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
              <a href="/" className="group relative block">
                <img
                  src={require('./src/e-cell-workshop-3.webp')}
                  alt=""
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className=" hidden text-xl font-medium text-white">Skinny Jeans Blue</h3>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>
      {/* training */}
      <section className='bg-white dark:bg-gray-900 dark:text-white'>
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <header className="text-center">
            <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">Training</h2>

            {/* <p className="mx-auto mt-4 max-w-md text-gray-500">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque praesentium cumque iure
        dicta incidunt est ipsam, officia dolor fugit natus?
      </p> */}
          </header>

          <ul className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-3">
            <li>
              <a href="/" className="group relative block">
                <img
                  src={require('./src/e-cell-training.webp')}
                  alt=""
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className=" hidden text-xl font-medium text-white">Casual Trainers</h3>


                </div>
              </a>
            </li>

            <li>
              <a href="/" className="group relative block">
                <img
                  src={require('./src/e-cell-selection-2.webp')}
                  alt=""
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className=" hidden text-xl font-medium text-white">Winter Jumpers</h3>


                </div>
              </a>
            </li>

            <li className="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
              <a href="/" className="group relative block">
                <img
                  src={require('./src/e-cell-training-2.webp')}
                  alt=""
                  className="aspect-square w-full object-cover transition duration-500 group-hover:opacity-90"
                />

                <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className=" hidden text-xl font-medium text-white">Skinny Jeans Blue</h3>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>

    </>
  )
}

export default Gallery
import React from 'react'
import'./App.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Index from "./pages/Index";
import Soon from './pages/Soon';
import NotFond from './pages/NotFond';
import Team from './pages/Team';
import Gallery from './pages/Gallery';

function App() {
  return (
     <Router>
            {/* <Navbar /> */}
            <Routes>
                <Route exact path="/" element={<Index />} />
                <Route path='/Team' element={<Team/>}/>
                <Route path="/404" element={<NotFond/>}/>
                <Route path="/soon" element={<Soon/>} />
                <Route path="/Gallery" element={<Gallery/>}/>
                <Route path="*" element={<Soon/>} />

            </Routes>
        </Router>
    );
}
  


export default App